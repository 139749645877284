<template>
    <div id="chat">
        <div class="chatBox">
            <div class="chatBox-top">
                <div class="chatBox-top-imgBox">
                    <van-image round width="2.5rem" height="2.5rem" src="https://tm-image.qichacha.com/ce577802df00fdf5dbf8a0aff5a8877a.jpg" />
                </div>
                <div class="chatBox-top-text"><span>Chat-GPT v2023.04.26</span></div>
            </div>
            <div ref="main" class="chatBox-middle">
                <div ref="chatInfo" class="chatInfo" id="chatInfo">
                    <div class="gpt-message-box" v-for="(item,index) in chatList"  :key="index" :class="[!item.isGPT?'my-message-box':'gpt-message-box']">
                        <div class="user-box-img">
                            <van-image round width="2.5rem" height="2.5rem"
                                :src="item.images" />
                        </div>
                        <div class="chat-message">
                            <div class="chat-message-name" :class="[!item.isGPT?'chat-message-name1':'chat-message-name']"><span>{{item.name}}</span><span class="nowDate">{{item.time}}</span>
                            </div>
                            <div class="chat-message-text" :class="[!item.isGPT?'chat-message-text1':'chat-message-text']">
                                <!-- <span v-html="item.message"></span> -->
                                <!-- <span v-if="item.isCode">
                                    <pre><code v-html="item.message"></code></pre>
                                </span> -->
                                <span>
                                    {{item.message}}
                                </span>
                                <!-- <v-md-preview :text="item.message"></v-md-preview> -->
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="chatBox-bottom">
                <!-- <van-divider dashed>GPT</van-divider> -->
                <div class="chatBox-textarea">
                    <van-cell-group inset>
                    <!-- <van-field
                        v-model="inputText"
                        rows="4"
                        autosize
                        required
                        type="textarea"
                        maxlength="50"
                        placeholder="请输入你的问题"
                        show-word-limit
                    /> -->
                    <van-field
                        v-model="inputText"
                        rows="4"
                        center
                        required
                        type="textarea"
                        clearable
                        show-word-limit
                        placeholder="请输入你的问题"
                    >
                    <template #button>
                    <van-button size="small" type="primary" @click="askQuestion">发送</van-button>
                    </template>
  </van-field>
                    </van-cell-group>
                </div>
                <!-- <div class="chatBox-sendOut">
                    <van-button class="sendOut" type="primary" @click="askQuestion">发送</van-button>
                </div> -->
            </div>
        </div>
	</div>
</template>
<script>
import { mapState, mapAcAtions, mapMutations } from 'vuex'
import { Field,Button,Toast,Divider,Image } from 'vant'
import  moment, { duration }  from "moment"
import config from '../config'
import MarkdownIt from 'markdown-it';
import GPTService from '../services/GPTService'
const md = new MarkdownIt();
export default {
    name: 'chat',
    components: {
      [Field.name]: Field,
      [Button.name]: Button,
      [Divider .name]: Divider,
      [Image .name]: Image,
      [Toast .name]: Toast,
    },
    computed: {
        ...mapState({
            isLogin: state => state.user.isLogin,
            userName: state => state.user.userName,
            token: state => state.user.token,
            photo: state => state.user.photo,
            nickName: state => state.user.nickName,
        }),
    },
    data () {
        return {
            inputText:"",
            chatList:[],
            currentMsgId:0,
            NickName:'',
            Profile:'',
            UserName:'',
            Profile:'',
            Photo:'',
            session_id:'',
            currentChatMessage:null,
        } 
    },
    methods: {
        appendMessage(msg){
            if(msg){
                msg.time=moment.utc(msg.time).local().format('YYYY-MM-DD HH:mm:ss')
                this.chatList.push(msg);
                this.scrollToBottom();
            }
        },
        askQuestion(){
            // this.sendMessage();
            // return;
            if(!this.inputText){
                // Toast.showToast('请输入')
                Toast.fail('请输入问题');
                return
            }
            var _data={
                "prompt":this.inputText,
                "session_id":this.session_id,
            };
            let myQuestion = {
                id:2,
                name:'我',
                time:new Date(),
                isGPT:false,
                images:'https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fsafe-img.xhscdn.com%2Fbw1%2Ff86d75bf-068a-4f0f-b4a4-1ebfe4fd3e84%3FimageView2%2F2%2Fw%2F1080%2Fformat%2Fjpg&refer=http%3A%2F%2Fsafe-img.xhscdn.com&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=auto?sec=1682834880&t=390d620088a4755410732e57ff8184e2',
                message:this.inputText
            }
            this.appendMessage(myQuestion);
            if(this.currentChatMessage){
                this.currentChatMessage=null;
            }
            // 主要通知
            // showNotify({ type: 'primary', message: '通知内容' });

            // 成功通知
            // showNotify({ type: 'success', message: '通知内容' });

            // 危险通知
            // showNotify({ type: 'danger', message: '通知内容' });

            // 警告通知
            // showNotify({ type: 'warning', message: '通知内容' });

            // Toast.showLoadingToast('请等待');
            // Toast.loading({
            //     message: '正在努力获取答案...',
            //     forbidClick: true,
            // });
            // const source = new EventSource(config.apiHost+`/api/gpt/generate`);
            // source.onmessage = (event) => {
            //     // const message = JSON.parse(event.data);
            //     // console.log(message);
            //     let customerData = {
            //         id:4,
            //         name:'GPT',
            //         isGPT:true,
            //         isCode:this.isContainCode(event.data),
            //         time:new Date(),
            //         images:'https://5b0988e595225.cdn.sohucs.com/images/20171117/295407efc6c040fda8c4e587a2483ae7.jpeg',
            //         message:event.data,
            //     }
            //     this.appendMessage(customerData);
            //     this.inputText = ''
            // };
            // source.addEventListener('finished',(event) => {
            //     debugger
            //     source&&source.close();
            // });
            // source.onerror = (event) => {
            //     debugger
            //     source&&source.close();
            // };
            fetch(config.apiHost+'/api/gpt/generate/', {
                method: 'POST',
                headers: {
                    'Access-Token':''
                },
                mode: 'cors',
                credentials: 'include',
                body: JSON.stringify(_data),
            })
            .then(response => response.json())
            .then(json=>{
                if(json.Success){
                    let gptMessage = {
                        id:4,
                        name:'GPT',
                        isGPT:true,
                        isCode:false,
                        time:new Date(),
                        images:'https://5b0988e595225.cdn.sohucs.com/images/20171117/295407efc6c040fda8c4e587a2483ae7.jpeg',
                        message:'',
                    }
                    // this.currentChatMessage=gptMessage;
                    // this.appendMessage(gptMessage);
                    this.inputText = ''
                }
            })
            .catch(e => {
                console.log(e);
            });
            // GPTService.ChatStream(_data).then(s=>{
            //     if (s.Success) {
            //         // 判断文本中是否包含反引号包裹的代码块
            //         let customerData = {
            //             id:4,
            //             name:'GPT',
            //             isGPT:true,
            //             isCode:this.isContainCode(s.Data),
            //             time:new Date(),
            //             images:'https://5b0988e595225.cdn.sohucs.com/images/20171117/295407efc6c040fda8c4e587a2483ae7.jpeg',
            //             message:s.Data,
            //         }
            //         this.appendMessage(customerData);
            //         this.inputText = ''
            //     }
            //     else{
            //         alert(s.Message);
            //     }
            // }).catch(e=>{
            //     Toast.fail('服务出现错误');
            // });
        },
        isContainCode(text){
            const regex1 = /`(?:[^`\\]|\\.)*`/g;
            const hasBacktickCodeBlock = regex1.test(text);
            // 判断文本中是否包含三个反引号包裹的代码块
            const regex2 = /```[\s\S]*?```/g;
            const hasTripleBacktickCodeBlock = regex2.test(text);

            if (hasBacktickCodeBlock || hasTripleBacktickCodeBlock) {
                return true;
            } else {
                return false;
            }   
        },
        scrollToBottom(){
            this.$nextTick(()=>{
                this.$refs.main.scrollTo({ top:this.$refs.chatInfo.scrollHeight, behavior: 'smooth' });
            })
        },
        formatDate() {//当前时间 2021-4-23 18:00:24 星期五
            let date = new Date();
            let year = date.getFullYear(); // 年
            let month = date.getMonth() + 1; // 月
            let day = date.getDate(); // 日
            let week = date.getDay(); // 星期
            let weekArr = ["星期日", "星期一", "星期二", "星期三", "星期四", "星期五", "星期六"];
            let hour = date.getHours(); // 时
            hour = hour < 10 ? "0" + hour : hour; // 如果只有一位，则前面补零
            let minute = date.getMinutes(); // 分
            minute = minute < 10 ? "0" + minute : minute; // 如果只有一位，则前面补零
            let second = date.getSeconds(); // 秒
            second = second < 10 ? "0" + second : second; // 如果只有一位，则前面补零
            this.nowDate = `${year}-${month}-${day} ${hour}:${minute}:${second} ${weekArr[week]}`;
		},
        chat: function () {
            var that=this;
        },
         //设置cookie
      setCookie: function (cname, cvalue, exdays) {
        var d = new Date();
        d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
        var expires = "expires=" + d.toUTCString();
        console.info(cname + "=" + cvalue + "; " + expires);
        document.cookie = cname + "=" + cvalue + "; " + expires;
        console.info(document.cookie);
      },
      //获取cookie
      getCookie: function (cname) {
        var name = cname + "=";
        var ca = document.cookie.split(';');
        console.log("获取cookie,现在循环")
        for (var i = 0; i < ca.length; i++) {
          var c = ca[i];
          console.log(c)
          while (c.charAt(0) == ' ') c = c.substring(1);
          if (c.indexOf(name) != -1){
            return c.substring(name.length, c.length);
          }
        }
        return "";
      },
      //清除cookie
      clearCookie: function (cname) {
        this.setCookie(cname, "", -1);
      },
      guid2() {
        function S4() {
            return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
        }
        return (S4() + S4() + "-" + S4() + "-" + S4() + "-" + S4() + "-" + S4() + S4() + S4());
      }
    },
    created: function () {
        
    },
    mounted: function () {//dom已渲染
        if(!this.isLogin){
            this.$router.push({ name: 'login'})
        }
        else{
            this.$store.dispatch('user/getUserInfo');
        }
        var that=this;
        var sessionId=this.getCookie('session_id');
        if(sessionId){
            this.session_id=sessionId;
        }
        else{
            let _session_id=this.guid2();
            this.setCookie('session_id',_session_id,1);//设置1天
            this.session_id=sessionId;
        }
        debugger
        this.sse = new EventSource(config.apiHost+'/api/gpt/stream?sessionId='+this.session_id);
        this.sse.onmessage = function(event) {
            if(event.data=='[DONE]'){
                that.currentChatMessage=null;
                return;
            }
            if(that.currentChatMessage){
                that.currentChatMessage.message+=event.data;
                that.scrollToBottom();
            }
            else{
                let customerData = {
                    id:4,
                    name:'GPT',
                    isGPT:true,
                    isCode:that.isContainCode(event.data),
                    time:new Date(),
                    images:'https://5b0988e595225.cdn.sohucs.com/images/20171117/295407efc6c040fda8c4e587a2483ae7.jpeg',
                    message:event.data,
                }
                that.appendMessage(customerData);
                that.currentChatMessage=customerData;
            }
        };
        // this.sse.start();
        // this.sse = new EventSource(config.apiHost+'/api/gpt/chatStream/');
        // this.sse.onmessage = function(event) {
        //     debugger
        //     const data = JSON.parse(event.data);
        //     console.log(data.choices[0].text);
        // };
        // this.sse.start();
        // this.$nextTick(() => {
        //     this.$refs.main.scrollTop = this.$refs.chatInfo.scrollHeight;
        // })
        // this.appendMessage(
        //     {
        //         id:2,
        //         name:'Air',
        //         isGPT:false,
        //         time:new Date(),
        //         images:'https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fsafe-img.xhscdn.com%2Fbw1%2Ff86d75bf-068a-4f0f-b4a4-1ebfe4fd3e84%3FimageView2%2F2%2Fw%2F1080%2Fformat%2Fjpg&refer=http%3A%2F%2Fsafe-img.xhscdn.com&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=auto?sec=1682834880&t=390d620088a4755410732e57ff8184e2',
        //         message:"mg.xhscdn.com%2Fbw1%tps://gimg2.baidu.com/image_search/src=http%3A%2F%2Fsafe-img.xhscdn.com%2Fbw1%"
        //     });
        //     this.appendMessage(
        //     {
        //         id:2,
        //         name:'GPT',
        //         isGPT:true,
        //         time:new Date(),
        //         images:'https://5b0988e595225.cdn.sohucs.com/images/20171117/295407efc6c040fda8c4e587a2483ae7.jpeg',
        //         message:"sss"
        //     });
        // if(!this.isLogin){
        //     this.$router.push({ name: 'login'})
        // }
        // else{
        //     UserService.GetUserInfo().then(data=>{
        //         debugger
        //         this.NickName=data.Data.NickName;
        //         this.UserName=data.Data.UserName;
        //         this.Profile=data.Data.Profile;
        //         this.Photo=data.Data.Photo;
        //     });
        // }
    },
}
</script>
<style lang="scss" scoped>
     @import url("https://fastly.jsdelivr.net/npm/vant@4/lib/index.css");
     html,
		body {
			margin:0; padding:20px; width:100%; height:100%;
		}
		#chat {
            // display: flex;
            // height: 100vh;
            // overflow: hidden;
            position:absolute;
            height:100%;
            width:100%;
            overflow: hidden;
            overflow-y: auto;
        }
        .chatBox {
			width: 100%;
            height: 100%;
			background-color: #fff;
            display: flex;
            flex-direction: column;
            // box-sizing: content-box;
			// border-radius: 0.625rem;
            .chatBox-top {
                width: 100%;
                display: flex;
                flex-wrap: nowrap;
                align-items: center;
                padding: 0.5rem;
                height:3rem;
                background-color:#1989fa;
            }
            .chatBox-middle {
                background-color: #fff;
                overflow: auto;
                // flex:1;
                height: calc(100% - 12rem);
                .chatInfo {
                    width: 100%;
                    height: 100%;
                    padding: 1rem;
                    box-sizing: border-box;
                }
            }
            .chatBox-bottom {
                // height: 10rem;
                padding-top: 1rem;
            }
		}
		.chatBox-top-imgBox {
			margin-left: 1.5rem;
		}
 
		.chatBox-top-text {
			margin-left: 1.25rem;
			font-size: 1rem;
			color: #fff;
		}
 
		
 
		.chatBox-textarea {
			width: 100%;
            border: solid 1px #eee;
		}

		
		.gpt-message-box {
			width: 100%;
			margin-bottom: 6px;
			display: flex;
			flex-direction: row;
		}
		.user-box-img {
			display: flex;
		}
 
		.chat-message {
			margin: 0 1.25rem;
		}
 
		.chat-message-name {
			font-size: 0.875rem;
			color: #888;
			display: flex;
			flex-direction: row;
		}
 
		.nowDate {
			margin: 0 0.625rem;
		}
 
		#chat .chat-message-text {
			margin-top: 0.85125rem;
            max-width: 20rem;
            padding:0.5rem 1rem;
            background-color: #E8E8E8;
            border-radius: 0.5rem;
            float: left;
            table-layout: fixed;
            word-break: break-all;
            overflow: hidden;
		}
 
		#chat .chat-message-text span{
			font-size: 0.9375rem;
			line-height: 1.5;
		}
        #chat .chat-message-text span-code{
			font-size: 0.9375rem;
			line-height: 1;
		}
		#chat .my-message-box {
			flex-direction: row-reverse;
		}
		#chat .chat-message-name1 {
			flex-direction: row-reverse;
		}
		#chat .chat-message-text1 {
			float: right;
		}
     
</style>
